  /* body {
  direction: ltr;
  font-family: "Cairo", sans-serif;
  font-weight: 600 !important;
  background-color: #fff0 !important;
  overflow-x: hidden;
  font-size: 16px !important
} */



  ::-webkit-scrollbar {
    width: .3em;
    height: .3em;
  }

  ::-webkit-scrollbar,
  ::-webkit-scrollbar-thumb {
    overflow: visible;
    border-radius: 4px;
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, .2);
  }


  .text-danger {
    color: red;
  }

  .form {
    width: 500px;
    margin: 0 auto;
    margin-top: 150px;
    background: #fff;
  }

  .section {
    margin-bottom: 3rem;
    transition: all 1s;
  }

  /* @media (min-width: 769px) {
    .section {
      padding-left: 80px;
    }
  } */

  .form-item {
    position: relative;
    margin-bottom: 25px;
  }

  .form-item input {
    display: block;
    height: 50px;
    background: transparent;
    border: solid 1px #ccc;
    transition: all 0.3s ease;
    padding: 0 15px;
    margin: auto;
  }

  .form-item input:focus {
    border-color: #9b5081;
    font-size: 20px;
  }

  .form-item label {
    position: absolute;
    cursor: text;
    z-index: 2;
    font-weight: bold;
    background: #fff;
    padding: 0px 10px;
    color: #999;
    transition: all 0.3s ease;
    right: 10px;
    font-weight: 600;
    font-size: 19px;
    top: -16px;
    padding-left: 5px;
    padding-right: 5px;
  }

  .form-item input:focus+label,
  .form-item input:valid+label {
    font-size: 16px;
    top: -16px !important;
  }

  .form-item input:focus+label {
    color: #9b5081;
  }

  .login-form {
    display: block;
    margin: auto;
    width: 34%;
    text-align: center;
    margin-top: 3rem;
  }

  .log {
    display: block;
    width: 100%;
    font-size: 19px;
  }

  .social-login {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .creat-account {
    margin-top: 2rem;
  }

  a {
    text-decoration: none;
    color: #2b7dd2;
    cursor: pointer;
  }

  .forget {
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 14px;
    float: left;
  }

  .form-forget {
    display: block;
    margin: auto;
    width: 34%;
    text-align: center;
    margin-top: 5rem;
  }

  .form-verfication {
    display: block;
    margin: auto;
    width: 34%;
    text-align: center;
    margin-top: 7rem;
  }

  .styles_react-code-input-container__tpiKG {
    width: 100% !important;
    margin-bottom: 2rem;
  }

  .change-form {
    display: block;
    margin: auto;
    width: 34%;
    text-align: center;
    margin-top: 7rem;
  }

  #root>form>div:nth-child(1) {
    margin-top: 15rem;
  }

  /* body {
  font-family: "Markazi Text", serif;
  font-weight: 400;
} */
  /* .btn-primary {
  color: #fff;
  background-color: #9b5081 !important;
  border-color: #9b5081 !important;
} */
  .logo {
    width: 35%;
    margin-bottom: 3rem;
  }

  #root>div>div>div>form>div.social-login>span:nth-child(1) {
    margin-top: 3rem;
  }

  .social-login span {
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 17px;
  }

  .login-icons img {
    width: 50%;
  }

  .creat-account span {
    font-size: 15px;
  }

  .form-control:focus {
    color: #212529;
    background-color: #fff;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(155 80 129 / 18%) !important;
  }

  #root>div.form-forget>form>button>a {
    color: white;
    font-size: 22px;
  }

  .forget-header span {
    font-size: 17px;
    color: gray;
  }

  .forget-header {
    margin-bottom: 3rem;
  }

  .form-forget>img {
    margin-bottom: 2rem;
  }

  #root>div.form-verfication>div.styles_react-code-input-container__tpiKG.undefined>div>input[type="tel"] {
    width: 58px;
    height: 54px;
    margin-right: 1rem;
    border-right: 1px solid #c5c9cf;
    border-radius: 10px;
  }

  .form-verfication>a {
    font-size: 22px;
  }

  .form-verfication .retry span {
    font-size: 16px;
  }

  .retry {
    margin-top: 1.5rem;
  }

  #root>div.form-verfication>img {
    margin-bottom: 2rem;
  }

  #root>form>button {
    font-size: 20px;
  }

  .danger {
    color: red;
    text-align: right;
    border-color: red !important;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
    font-size: 14px !important;
    display: flex;
    justify-content: start;
  }

  .danger-e {
    color: red;
    font-size: 18px;
    margin-bottom: 1rem;
  }

  #root>form>div>div:nth-child(2)>div>select {
    height: 50px;
    text-align: right;
    color: gray;
  }

  .css-1s2u09g-control {
    min-height: 44px !important;
  }

  .css-1pahdxg-control {
    min-height: 44px !important;
  }

  #root>form>div>div.creat-account {
    margin-top: 1rem;
  }

  .logo-loder {
    width: 100%;
    height: 100vh;
  }

  @keyframes example {
    from {
      opacity: 0.8;
    }

    to {
      opacity: 0;
    }
  }

  /* The element to apply the animation to */
  .logo-loder {
    animation-name: example;
    animation-duration: 3s;
  }

  #root>form>div.social-login>div>button>span {
    display: none;
  }

  #root>form>div.social-login>div>button>div {
    margin-right: 0 !important;
    background: none !important;
  }

  #root>form>div.social-login>div>button {
    border-radius: 50% !important;
  }

  #root>form>div.social-login>div>button>div {
    padding: 6px !important;
  }

  .login-icons img {
    width: 55%;
  }

  .social-login>div>button>span {
    display: none !important;
  }

  .social-login>div>button>div {
    margin-right: 0 !important;
  }

  .social-login>div>button {
    border-radius: 50% !important;
  }

  .social-login>div>button>div {
    background: none !important;
    border-radius: 2px !important;
    padding-left: 9px !important;
    padding-right: 7px !important;
    padding-top: 5px !important;
    padding-bottom: 7px !important;
  }

  .login-icons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
  }

  .form-group {
    overflow: hidden;
  }

  .social-login>div>button {
    padding-left: 5px;
    padding-right: 2px;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .login-icons>button>span {
    display: none !important;
  }

  #root>form>div>div.login-icons>button>div {
    margin-right: 0 !important;
    background: none !important;
  }

  .login-icons>button {
    border-radius: 50% !important;
  }

  .login-icons>button:nth-child(1) {
    background-color: rgb(255, 255, 255);
    display: inline-flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.54);
    box-shadow: rgb(0 0 0 / 24%) 0px 2px 2px 0px, rgb(0 0 0 / 24%) 0px 0px 1px 0px;
    padding: 0px;
    border-radius: 2px;
    border: 1px solid transparent;
    font-size: 14px;
    font-weight: 500;
    font-family: Roboto, sans-serif;
  }

  #root>form>div>div.login-icons>button>div {
    border-radius: 2px !important;
    padding-left: 9px !important;
    padding-right: 7px !important;
    padding-top: 5px !important;
    padding-bottom: 7px !important;
  }

  .login-icons>span>button {
    background-color: rgb(255, 255, 255) !important;
    display: inline-flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.54);
    box-shadow: rgb(0 0 0 / 24%) 0px 2px 2px 0px, rgb(0 0 0 / 24%) 0px 0px 1px 0px !important;
    padding: 0px !important;
    border: 1px solid transparent !important;
    font-size: 19px !important;
    color: #1f5c9c !important;
    padding: 5px !important;
    padding-left: 12px !important;
    padding-right: 10px !important;
    padding-top: 7px !important;
    border-radius: 50% !important;
    margin-left: 0.5rem !important;
    padding-bottom: 0px;
  }

  .me-auto {
    margin-left: auto !important;
    margin-right: 0 !important;
  }

  #navbarScrollingDropdown {
    display: flex;
    align-items: center;
    color: white;
    font-size: 20px;
    margin-left: 1rem;
  }

  .dropdown-toggle::after {
    margin-right: 0.5rem;
  }


  #dropdown-basic {
    margin-left: 1rem;
  }

  .dropdown .dropdown-toggle::after {
    display: none !important;
  }

  .nav-item.dropdown .dropdown-toggle::after {
    display: block !important;
  }

  .fa-user:before {
    content: "\f007";
    font-size: 22px;
  }

  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
    margin-right: 3rem !important;
  }

  .dropdown,
  .dropend,
  .dropstart,
  .dropup {
    position: relative;
    padding-left: 1rem;
  }

  .breadcrumb {
    font-size: 21px;
    margin-right: 2rem;
    margin-top: 1.5rem;
  }

  .header ol li:nth-child(2) {
    margin-right: 1rem;
  }

  hr {
    height: 1px;
    color: #e6dede;
    margin-top: 0;
  }

  .user-data {
    color: white;
  }

  .dropdown,
  .dropend,
  .dropstart,
  .dropup {
    position: relative;
    padding-left: 1rem;
    display: flex !important;
    align-items: center;
  }

  .user-data span {
    font-size: 21px;
  }

  .breadcrumb-item+.breadcrumb-item::before {
    color: #e7ebf0 !important;
    content: var(--bs-breadcrumb-divider, "/");
  }

  .dropdown-item {
    text-align: right;
  }

  th {
    color: rgb(66, 66, 66);
    font-weight: 600;
  }

  .page-link {
    border: none;
    background: none;
    font-size: 21px !important;
    color: black;
  }

  .page-item.active .page-link {
    z-index: 0;
    color: #fff;
    background-color: #9b508163;
    border-color: #9b5081;
    /* border-radius: 50%; */
    padding: 9px;
    padding-top: 0;
    padding-bottom: 0;
  }

  /* #root
  > div:nth-child(1)
  > div.card
  > div
  > div
  > div.d-flex.align-items-cemter
  > button {
  font-size: 19px;
} */
  td {
    font-size: 20px;
  }

  .search {
    font-size: 20px;
  }

  .btn-secondary {
    color: #fff;
    background-color: #316ca9;
    border-color: #316ca9;
    font-size: 20px;
  }

  .page-header {
    margin-bottom: 2rem;
  }

  .no-bottom-border th {
    border-bottom: none;
  }

  .city-table>table>tbody>tr:nth-child(1)>td {
    font-size: 18px;
  }

  .table>:not(:first-child) {
    border-top: 2px solid #d3d2d2;
  }

  td {
    font-size: 18px;
  }

  table {
    margin-top: 1rem;
    margin-bottom: 1.5rem;
  }

  .front-card {
    width: 96%;
    display: block;
    margin: auto;
    padding: 1rem;
    border-top-right-radius: 24px;
    border-top-left-radius: 24px;
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
    position: absolute;
    top: 12rem;
    left: 2rem;
    height: 71vh;
  }

  form>button>a {
    color: white;
  }

  form>button>a:hover {
    color: white;
  }

  .facebook-button {
    margin-right: 0.5rem !important;
  }

  .login-form.reg .login-icons {
    margin-top: 0;
  }

  .login-form.reg .social-login span {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    font-size: 17px;
  }

  #srearchicon>span>input {
    text-align: right;
    font-size: 18px;
  }

  .d-flex.align-items-stretch.ms-1.ms-lg-3 i {
    font-size: 20px;
  }

  .fa-columns:before {
    content: "\f0db";
    font-size: 20px;
    color: #808080d6;
  }

  .fa-user:before {
    content: "\f007";
    font-size: 17px;
    color: #808080d6;
  }

  /*.svg-icon {*/
  /*  display: flex;*/
  /*  align-items: center;*/
  /*  background: #dcdcdc96;*/
  /*  padding: 5px;*/
  /*  border-radius: 7px;*/
  /*}*/
  /* .fa-search {
  position: absolute;
  color: #80808096;
  left: 15.5rem;
  font-size: 16px !important;
} */
  /*#profileIcon {*/
  /*  background: #dcdcdc96;*/
  /*  padding: 5px 9px;*/
  /*  border-radius: 7px;*/
  /*}*/
  #languageIcon {
    /* background: #dcdcdc96; */
    padding: 5px 9px;
    border-radius: 50%;
   }

  #srearchicon {
    background: none;
    padding: 0;
    border-radius: 0;
    display: flex;
    align-items: center;
  }

  #srearchicon>input {
    font-size: 19px;
    text-align: left;
  }

  /* .header {
  margin-top: 2rem;
} */
  .logoo img {
    width: 83%;
    margin-right: 1rem;
  }

  .nav-link {
    font-size: 23px;
    /* padding: 1rem 1.5rem; */
  }

  .nav-tabs {
    margin: auto;
  }

  .nav-tabs .nav-link {
    margin-bottom: -1px;
    background: 0 0;
    border: 1px solid #00000014;
    padding-left: 7rem;
    padding-right: 7rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .nav-link {
    color: #9b5081;
  }

  .nav-link:focus,
  .nav-link:hover {
    color: #9b5081;
  }

  #searchdiv {
    display: none !important;
  }

  #root>div>div>div>div>form>div.creat-account>span>a {
    font-size: 15px;
    margin-right: 5px;
  }

  /* .h2, h2 {
  font-size: 21px;
  font-weight: bold;
} */
  #root>div.form-verfication>div.retry>span>a {
    font-size: 14px;
  }

  #root>form>div>div.creat-account>span>a {
    font-size: 15px;
    margin-right: 5px;
  }

  .header {
    /* background: #470d33; */
    padding-top: 1rem;
    padding-bottom: 9rem;
    margin-top: 0;
    /* background: url(../images/header.png); */
    background-repeat: no-repeat;
    background-size: cover;
  }

  html {
    background: none !important;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #f9fafb !important;
    font-family: "Tajawal", sans-serif;
  }

  body {
    font-family: "Cairo", sans-serif;
    font-weight: 600 !important;
  }

  .lang {
    font-weight: bolder;
    font-size: 14px;
    margin-top: 2px;
  }



  .navs {
    width: 23%;
    position: absolute;
    top: 0.5rem;
    left: 15rem;
  }

  .home-navs {
    display: flex;
    justify-content: center;
  }

  .nav-item {
    width: 9rem;
    height: 9rem;
    text-align: center;
    background: white;
    border-radius: 15px;
    margin-bottom: 1rem;
    border: 1px solid #dcdcdc85;
  }

  .nav {
    justify-content: center;
  }

  @media only screen and (max-width: 992px) {
    .nav {
      justify-content: start;
    }
  }

  @media only screen and (max-width: 767px) {
    .nav {
      justify-content: center;
    }
  }

  @media (max-width: 1199px) {
    .f-sec .home-navs {
      margin-top: 25px;
      order: 2;
    }

    .f-sec .imagee {
      order: 1;
    }

  }

  @media (min-width: 1200px) {

    .nav .last {
      width: 100% !important;
    }
  }

  .out {
    outline: none;
  }

  .fa-user:before {
    content: "\f007";
    font-size: 17px;
    color: #252424d6;
  }

  /*.imagee{*/
  /*  margin-left: 223px;*/
  /*}*/
  .navicon {
    width: 27%;
    display: block;
    margin: auto;
  }

  .nav-link {
    color: #9b5081;
    display: flex;
    flex-direction: column;
    margin-top: 1.2rem;
  }

  .home {
    width: 57%;
    display: block;
    margin-right: auto;
    background: white;

    padding: 1rem;
    border-radius: 25px;
    position: absolute;
    left: 5rem;
    top: 9rem;
  }

  .home-data {
    text-align: left;
  }

  .home-data a {
    margin-top: 2rem;
    font-weight: 400 !important;
    font-size: 19px;
  }

  /* .customer-data{
  margin-left: -114px;
} */
  /* .customer-data .card {
  border-radius: 15px;
} */
  /*.customer-data span {
  font-weight: bold;
  color: #808080b8;
   font-size: 15px;
} */
  .cus {
    width: auto;
    position: relative;
    /* right: 4rem;
  top: 7rem; */
  }

  .cus1 {
    width: 57%;
    position: absolute;
    right: 17rem;
    top: 7rem;
  }

  .cus2 {
    width: auto;
    /* float: right; */
  }

  .cus img {
    margin: auto;
    display: block;
    width: 61%;
  }

  /* .btn-success {
  color: #fff;
  background-color: #7239ea;
  border-color: #7239ea;
  display: block;
  margin: auto;
  margin-top: 1rem;
  width: 24%;
  font-weight: bolder;
}
.btn-success:hover {
  color: #fff;
  background-color: #7239ea;
  border-color: #7239ea;
  width: 24%;
} */
  a {
    font-size: 17px;
  }

  .h4,
  h4 {
    font-size: 16px;
    font-weight: 400;
  }

  .tableHeader {
    z-index: 2;
    right: 0px;
    position: inherit !important;
  }

  .tableHeader th {
    height: 50px;
  }

  .tableHeader .status {
    padding: 0.5rem 2.5rem;
  }

  .tableHeader .status2 {
    padding: 0.5rem 1rem;
  }

  .tableBody td {
    min-width: min-content;
  }

  .tableBody .d-flex {
    color: #6c757d !important;
  }

  .table td,
  .table th,
  .table tr {
    border-color: inherit;
    border-width: inherit;
    border-style: inherit;
    text-transform: inherit;
    font-weight: inherit;
    font-size: inherit;
    color: inherit;
    height: inherit;
    min-height: inherit;
  }

  .isDeleted {
    background-color: #d8f7d8;
    padding: 3px 15px;
    border-radius: 20%;
    color: #4aa34a !important;
  }

  .isBlackListed {
    background-color: #f7d8d8;
    padding: 3px 15px;
    border-radius: 20%;
    color: #a34a4a !important;
  }

  .card1 {
    width: 960px;
  }

  .card1 .d-flex {
    padding: 3px;
    justify-content: center !important;
  }

  .card2 {
    width: 630px;
  }

  .tableHeaderRow {
    height: 50px;
    background-color: white;
    vertical-align: middle;
  }

  .tableHeaderRow .action {
    background-color: #d3d2d2;
    right: 0px;
  }

  .table>:not(caption)>*>* {
    padding: 0.5rem 0.5rem !important;
  }

  .fixedTd {
    z-index: 2;
    right: 0px;
    background-color: #d3d2d2 !important;
  }

  .actions {
    display: flex;
    /*background-color: #d3d2d2;*/
    /* margin-left: 30px; */
  }

  .actions .action1 {
    cursor: pointer;
    width: 40px;
    height: 40px;
    margin: 5px;
    border-radius: 20%;
    font-size: large;
    text-align: center;
    z-index: 0;
    background-color: #009ef7;
  }

  .actions .action2 {
    cursor: pointer;
    width: 40px;
    height: 40px;
    margin: 5px;
    border-radius: 20%;
    font-size: large;
    text-align: center;
    z-index: 2;
    background-color: red;
  }

  .actions .action1 i {
    color: rgb(255, 255, 255);
  }

  .actions .action2 i {
    color: rgb(255, 255, 255);
  }

  .actions .action1 .edit {
    margin-top: 10px;
    margin-left: 5px;
  }

  .actions .action2 .delete {
    margin-top: 10px;
  }

  .details {
    width: 40px;
    height: 30px;
    border-radius: 10px;
    font-size: large;
    text-align: center;
    background-color: #009ef7;
    margin: auto;
  }

  .details i {
    color: white;
    vertical-align: middle;
  }

  .NewB {
    background-color: #133f85;
    color: white;
    -webkit-font-smoothing: antialiased;
    border: none;
    margin-right: 742px;
    border-radius: 0.475rem;
    width: 100px;
    height: 44px;
    text-align: center;
    vertical-align: middle;
  }

  .NewB2 {
    background-color: #133f85;
    color: white;
    -webkit-font-smoothing: antialiased;
    border: none;
    border-radius: 0.475rem;
    height: 44px;
    width: max-content;
    padding: 0px 15px;
  }

  .text {
    font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
    -webkit-font-smoothing: antialiased;
    color: #5e6278;
    margin-top: 8px;
  }

  .text1 {
    text-align: left;
  }


  .page-item a {
    color: #5e6278;
  }

  .page-item i {
    color: #5e6278;
  }

  .page-link {
    /*background-color: #ffffff;*/
    padding: 0.375rem 0.75rem;
    margin-left: 0px;
  }

  .page-link .previous {
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;
  }

  .page-item.active .page-link {
    z-index: 0;
    color: #ffffff;
    background-color: #009ef7;
    border-color: transparent;
  }

  .container1 {
    margin-left: 0px;
    width: 100%;
    height: 385px;
  }

  .scroll {
    overflow-y: auto;
    position: relative;
  }

  .scroll::-webkit-scrollbar-track {
    border-radius: 15px;
    background-color: #f5f5f5;
  }

  .scroll::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    background-color: #f5f5f5;
  }

  .scroll::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #c3c3c3;
  }

  /************Settings***************************************************************/

  .nav-line-tabs .nav-item1 {
    margin: -1px 7px;
  }

  .nav-line-tabs .nav-item1 .nav-link1.active,
  .nav-line-tabs .nav-item1 .nav-link1:hover:not(.disabled),
  .nav-line-tabs .nav-item1.show .nav-link1 {
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid #009ef7;
    transition: color 0.2s ease, background-color 0.2s ease;
  }

  .nav-line-tabs .nav-item1 .nav-link1.active2,
  .nav-line-tabs .nav-item1 .nav-link1:hover:not(.disabled),
  .nav-line-tabs .nav-item1.show .nav-link1 {
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid #009ef7;
    color: whitesmoke;
    fill: whitesmoke;
    /* transition: color 0.2s ease, background-color 0.2s ease; */
  }

  .nav-line-tabs .nav-item1:first-child .nav-link1 {
    margin-left: 0;
  }

  .nav-line-tabs .nav-item1 .nav-link1 {
    color: #009ef7;
    padding: 0.5rem 0;
    margin: 0 1rem;
  }

  .nav-tabs .nav-link1 {
    background: none;
    border-top-left-radius: 0.475rem;
    border-top-right-radius: 0.475rem;
  }

  .nav-link1 {
    display: block;
    padding: 0.5rem 1rem;
    color: #009ef7;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out;
  }

  .nav-line-tabs .nav-item1:last-child .nav-link1 {
    margin-right: 0;
  }

  .sideBar {
    width: 120px;
    background-color: white;
    height: 650px;
  }

  .sideBar button {
    text-align: center;
    width: 58px;
    height: 58px;
    border-radius: 22px;
    border: none;
  }

  .tip {
    text-decoration: none;
  }

  .tip:hover {
    position: relative;
  }

  /* .tip .span1 {
  display: none;
}

.tip:hover .span1 {
  border: white 1px solid;
  padding: 5px 20px 5px 5px;
  display: block;
  inset:auto;
  z-index: 1000;
  background-color: white;
  color: gray;
  width: 100px;
   position: fixed; 
  /* top: 25px;
  left: -40px; 
  text-decoration: none;
}
.tip .span2{
  display: none;
}
.tip:hover .span2{
  border: white 1px solid;
  padding: 5px 20px 5px 5px;
  display: block;
  inset:100px 0px auto auto;
  z-index: 1000;
  background-color: white;
  color: gray;
  width: 100px;
   position: fixed; 
  text-decoration: none;
} */
  .scroll1 {
    overflow-y: auto;
    position: relative;
  }

  .scroll1::-webkit-scrollbar-track {
    border-radius: 15px;
    background-color: #f5f5f5;
  }

  .scroll1::-webkit-scrollbar {
    width: 6px;
    height: 0px;
    background-color: #f5f5f5;
  }

  .scroll1::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #c3c3c3;
  }

  .menu-item {
    display: block;
    padding: 0;
    align-items: stretch;
    justify-content: center;
  }

  .menu-item .menu-content {
    padding: 0.65rem 1rem;
  }

  /* .dropdown button:hover, .dropdown button:focus {
  background-color: #2980B9;
}  */
  .menu-item .menu-sub::before {
    display: flex;
  }

  .menu-item>.menu-link {
    align-items: center;
    justify-content: center;
    padding: 0;
    cursor: pointer;
    display: flex;
    flex: 0 0 100%;
    outline: 0 !important;
  }

  .menu-item>.menu-link .menu-icon .svg-icon2 svg [fill]:not(.permanent):not(g) {
    transition: fill .3s ease;
  }

  .menu-item .menu-link .menu-icon .svg-icon2 svg [fill]:not(.permanent):not(g) {
    fill: #b5b5c3;
  }

  .menu-item .menu-link,
  .menu-item .menu-link rect {
    color: #cecece;
  }

  .menu-item,
  .menu-link,
  .menu-link rect {
    fill: #cecece;
  }

  .menu-item .menu-link:hover {
    color: #009ef7;
    fill: #009ef7;
  }

  .menu-item .menu-link:hover rect {
    color: #009ef7;
    fill: #009ef7;
  }

  .menu-item .menu-link::after {
    color: black;
  }

  .menu-link.active {
    transition: color .2s ease, background-color .2s ease;
    color: #009ef7 !important;
  }

  .menu-link.active2,
  .menu-link.active rect {
    /* transition: color .2s ease,background-color .2s ease; */
    color: #009ef7 !important;
    fill: whitesmoke;
  }

  .menu-item>.menu-link.active .menu-icon {
    background-color: #009ef7;
  }

  .menu-item>.menu-link.active2 .menu-icon,
  .menu-item>.menu-link.active .menu-icon rect {
    background-color: #009ef7;
    fill: whitesmoke;
  }

  .pro-sidebar .pro-menu .pro-menu-item a {
    font-size: 15px !important;
  }

  .menu-link .menu-icon {
    background-color: #009ef7;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    width: 58px;
    height: 58px;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    border-radius: 22px;
    background-color: #EFEFEF;
    flex-shrink: 0;

  }

  .dropdown-menu.show {
    position: fixed !important;
    margin-left: 94px;
    margin-top: -70px;
    border-radius: 10%;
    width: min-content;
    text-align: center;
    padding: 0px;
  }

  .dropdown-menu li {
    display: flex;
  }

  .dropdown-menu li a {
    color: #7e8299;
    text-align: left !important;
  }

  .dropdown-menu li label {
    color: #7e8299;
  }

  .dropdown-menu li a:hover {
    color: #009ef7;
    background-color: white;
  }

  .dropdown-menu .menu-bullet {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.25rem;
    margin-left: 0.5rem;
  }

  .bullet-dot {
    width: 4px;
    height: 4px;
    border-radius: 100% !important;
  }

  .bullet {
    display: inline-block;
    background-color: #b5b5c3;
    border-radius: 6px;
    flex-shrink: 0;
  }

  .dropdown-item {
    padding: .25rem !important;
  }

  .card-header h3 {
    margin-bottom: 0px !important;
  }

  /* .card{
  border: 0;
  box-shadow: 0 0 20px rgb(94 98 120 / 4%);
  border-radius: 1.25rem !important;
}
.card .card-body {
  padding: 2rem 0rem 2rem 0.25rem;
}
.card-body {
  flex: 1 1 auto;
}
.card.card-stretch {
  height: calc(100% - var(--bs-gutter-y));
} */
  .Atag {
    box-shadow: none;
    color: #3f4254;
    border: 0;
    border-radius: 20% !important;
    display: inline-flex;
    outline: 0;
    text-align: left;
    padding: 1rem;
    align-items: flex-start;
    width: 100%;
    flex-direction: column;
  }

  /* .Atag span:hover{
  color: rgb(49, 108, 169);
}
.Atag svg path:hover{
  fill: rgb(49, 108, 169);
} */
  .Atag .spanTag {
    flex-shrink: 0;
    line-height: 0;
    /* margin-right: 1.5rem; */
    margin-top: 2rem;
    color: #3f4254;
    text-align: left;
    margin-bottom: 5px;
  }

  .spanTwo {
    font-weight: bold;
    /* font-size:0.9rem; */
    text-align: left;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    /* margin-right: 1.5rem; */
  }

  .fill a {
    color: #3f4254;
    fill: #b5b5c3;
  }

  .fill a:hover {
    color: #009ef7;
    fill: #009ef7;
  }

  .svg-icon2 {
    line-height: 1;
  }

  .svg-icon2.svg-icon-2x svg {
    height: 2rem !important;
    width: 2rem !important;
  }

  .menu-link.active .menu-icon .svg-icon2 svg [fill]:not(.permanent):not(g) {
    transition: fill .3s ease;
    fill: #fff !important;
  }

  .menu-link.active2 .menu-icon .svg-icon2 svg [fill]:not(.permanent):not(g) {
    fill: #fff !important;
  }

  .menu-link[data-title]:hover:after {
    visibility: visible;
  }

  .menu-link[data-title]:after {
    content: attr(data-title);
    background-color: white;
    color: #b5b5c3;
    font-size: 120%;
    position: fixed;
    padding: 4px 20px 4px 20px;
    visibility: hidden;
    margin-right: 200px;
    border-radius: 10px;
    z-index: 10;
    inset: auto;
  }

  .tooltip1 {
    position: relative;
    display: inline-block;
  }

  .tooltip1 .tooltiptext1 {
    visibility: hidden;
    width: 120px;
    background-color: white;
    color: #828288;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: fixed;
    z-index: 1;
    top: 2.5rem;
    left: 8rem;
    box-shadow: 0 .5rem 1.5rem .5rem rgba(0, 0, 0, .075) !important;
  }

  .tooltip1 .tooltiptext1::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent white transparent transparent;
  }

  .tooltip1:hover .tooltiptext1 {
    visibility: visible;
  }

  .title[data-title]:hover:after {
    visibility: visible;
  }

  .title[data-title]:after {
    content: attr(data-title);
    background-color: white;
    color: #a5a5b1;
    font-size: 110%;
    position: fixed;
    padding: 4px 20px 4px 20px;
    visibility: hidden;
    border-radius: 10px;
    margin-right: 80px;
    margin-top: 340px;
    inset: 0px 0px auto auto;
  }

  .title1[data-title]:hover:after {
    visibility: visible;
  }

  .title1[data-title]:after {
    content: attr(data-title);
    background-color: white;
    color: #a5a5b1;
    font-size: 110%;
    position: fixed;
    padding: 4px 20px 4px 20px;
    visibility: hidden;
    border-radius: 10px;
    margin-right: 80px;
    margin-top: 430px;
    inset: 0px 0px auto auto;
  }

  .title2[data-title]:hover:after {
    visibility: visible;
  }

  .title2[data-title]:after {
    content: attr(data-title);
    background-color: white;
    color: #a5a5b1;
    font-size: 110%;
    position: fixed;
    padding: 4px 20px 4px 20px;
    visibility: hidden;
    border-radius: 10px;
    margin-right: 80px;
    margin-top: 520px;
    inset: 0px 0px auto auto;
  }

  .title3[data-title]:hover:after {
    visibility: visible;
  }

  .title3[data-title]:after {
    content: attr(data-title);
    background-color: white;
    color: #a5a5b1;
    font-size: 110%;
    position: fixed;
    padding: 4px 20px 4px 20px;
    visibility: hidden;
    border-radius: 10px;
    margin-right: 100px;
    margin-top: 610px;
    inset: 0px 0px auto auto;
  }

  .detailsRow {
    width: 100%;
    height: 50px;
  }

  .detailsRow label {
    padding: 11px 20px;
    font-weight: bolder !important;
    vertical-align: middle;
  }

  .detailsRow span {
    padding: 11px 20px;
    vertical-align: middle;
  }

  .detailsRow2 {
    width: 100%;
    height: 50px;
    background-color: whitesmoke;
  }

  .detailsRow3 {
    width: 100%;
    height: 50px;
    background-color: whitesmoke;
  }

  .detailsRow3 label {
    padding: 11px 20px;
    font-weight: bolder !important;
    vertical-align: middle;
  }

  .detailsRow3 span {
    padding: 0px;
    vertical-align: middle;
    color: #faaf00;
    font-size: 18px !important;
  }

  .detailsRow2 label {
    padding: 11px 20px;
    font-weight: bolder !important;
    vertical-align: middle;
  }

  .detailsRow2 span {
    padding: 11px 20px;
    vertical-align: middle;
  }

  .detailsRowImage {
    width: 100%;
    height: 80px;
    background-color: white;
  }

  .detailsRowImage label {
    padding: 11px 20px;
    font-weight: bolder !important;
    vertical-align: middle;
  }

  .detailsRowImage span {
    padding: 11px 20px;
    vertical-align: middle;
  }

  .odd {
    padding: 0px 17px !important;
    border-radius: 10px;
    color: #009EF7 !important;
    background-color: #F1FAFF;
  }

  .form1 {
    /* width: 866px;
  padding: 0px 50px;
  margin:0px; */
    /*font-family: sans-serif;*/
    background: #fff;

  }

  /* .gridWidth{
  width: 90%;
  /* margin-left: 8rem;
} */
  .gridWidth2 {
    width: 65%;
  }

  .gridWidth3 {
    width: 55%;
  }

  .form-control1 {
    background-color: #f5f8fa;
    border-color: #f5f8fa;
    /* color: #a3a3a3; */
    color: black;
    transition: color .2s ease, background-color .2s ease;
  }

  .form-select.form-select-solid {
    background-color: #f5f8fa;
    color: #adadad;
    transition: color .2s ease, background-color .2s ease;
  }

  .form-select1 {
    border-radius: 0.95rem;
  }

  .form-control1::placeholder {
    color: #b8b8b8;
  }

  .font {
    font-family: "Cairo", sans-serif;
  }

  .select {
    background-color: #f5f8fa;
    color: #5e6278;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    border-radius: 0.3rem;
    direction: ltr !important;
    height: 44px;
  }

  /* .css-1s2u09g-control {
  background-color: hsl(204deg 33% 97%) !important;
  border-color: hsl(204deg 33% 97%) !important ;
  border-radius: 0.3rem !important;
} */
  /* .css-14el2xx-placeholder {
  color: hsl(0deg 0% 72%) !important;
  font-size: 1.25rem;

}
.css-319lph-ValueContainer {
  padding: 2px 12px;
} */
  .image-input {
    position: relative;
    display: inline-block;
    border-radius: 0.95rem;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .image-input.image-input-outline .image-input-wrapper {
    border: 3px solid #fff;
    box-shadow: 0 0.5rem 1.5rem 0.5rem rgb(0 0 0 / 8%);
  }

  .image-input .image-input-wrapper {
    width: 120px;
    height: 120px;
    border-radius: 0.95rem;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .btn.btn-icon:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush) {
    border: 0;
  }

  .btn.btn-icon1.btn-circle {
    border-radius: 50%;
  }

  .image-input [data-kt-image-input-action=change] {
    cursor: pointer;
    position: absolute;
    right: -10px;
    top: -10px;
  }

  .btn.btn-icon1 {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0;
  }

  .shadow {
    box-shadow: 0 .5rem 1.5rem .5rem rgba(0, 0, 0, .075) !important;
  }

  .btn.btn-icon1 i {
    padding: 0;
    margin: 0;
    line-height: 1;
    color: #a1a5b7;
  }

  .btn.btn-icon1 i:hover {
    color: #009ef7;
  }

  .btn i {
    display: inline-flex;
    vertical-align: middle;
  }

  .image-input [data-kt-image-input-action=change] input {
    width: 0 !important;
    height: 0 !important;
    overflow: hidden;
    opacity: 0;
  }

  .image-input [data-kt-image-input-action=cancel] {
    display: none;
  }

  .image-input [data-kt-image-input-action=cancel],
  .image-input [data-kt-image-input-action=remove] {
    position: absolute;
    right: -10px;
    bottom: -5px;
  }

  .form-text {
    margin-top: 0.5rem;
    font-size: .925rem;
    color: #a1a5b7;
  }

  .rate span {
    font-size: 18px;
    color: #faaf00;
  }

  .css-12jo7m5 {
    color: hsl(222deg 100% 66%) !important;
  }

  .ReactTags .app {
    padding: 40px;
    text-align: center;
  }

  /*.container {*/
  /*  margin: auto;*/
  /*  width: 50%;*/
  /*}*/
  .ReactTags__tags {
    position: relative;
  }

  .ReactTags__clearAll {
    cursor: pointer;
    padding: 10px;
    margin: 10px;
    background: #f88d8d;
    color: #fff;
    border: none;
  }

  /* Styles for the input */
  .ReactTags__tagInput {
    border-radius: 2px;
    display: inline-block;
  }

  .ReactTags__tagInput input.ReactTags__tagInputField,
  .ReactTags__tagInput input.ReactTags__tagInputField:focus {
    min-height: calc(1.5em + 1rem + 2px);
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    border-radius: 0.3rem;
    margin: 0px;
    border: 1px solid;
    min-width: 476px;
    background-color: #f5f8fa;
    border-color: #f5f8fa !important;
    color: #a3a3a3;
    transition: color .2s ease, background-color .2s ease;
    outline: none;
  }

  ::placeholder {
    color: #b8b8b8;
  }


  .ReactTags__editInput {
    border-radius: 1px;
  }

  .ReactTags__editTagInput {
    display: inline-flex;
  }

  /* Styles for selected tags */
  .ReactTags__selected span.ReactTags__tag {
    border: 1px solid #ddd;
    background: #63bcfd;
    color: white;
    font-size: 12px;
    display: inline-block;
    padding: 5px;
    margin: 3px;
    border-radius: 2px;
  }

  .ReactTags__selected span.ReactTags__tag:first-child {
    margin-left: 0px;
  }

  .ReactTags__selected a.ReactTags__remove {
    color: #aaa;
    margin-left: 5px;
    cursor: pointer;
  }

  /* Styles for suggestions */
  .ReactTags__suggestions {
    position: absolute;
    top: 60px;
  }

  .ReactTags__suggestions ul {
    list-style-type: none;
    box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
    background: white;
    width: 200px;
  }

  .ReactTags__suggestions li {
    border-bottom: 1px solid #ddd;
    padding: 5px 10px;
    margin: 0;
  }

  .ReactTags__suggestions li mark {
    text-decoration: underline;
    background: none;
    font-weight: 600;
  }

  .ReactTags__suggestions ul li.ReactTags__activeSuggestion {
    background: #b7cfe0;
    cursor: pointer;
  }

  .ReactTags__remove {
    border: none;
    cursor: pointer;
    background: none;
    color: white;
  }

  .calender {
    display: flex;
    align-items: center;
  }

  .calender input {
    font-size: 13px;
    border-radius: 4px;
    box-shadow: inset 0 2px 2px #e9e9e9;
    border: 1px solid #aeaeae;
    line-height: 16px;
    padding: 6px 10px 5px;
    width: 100px;
    height: 44px;
    margin-left: 10px;
  }

  .calender input:focus {
    outline: none;
    border-color: #aeaeae;
    box-shadow: inset 0 2px 2px #e9e9e9, 0 0 10px 0 rgb(73 107 125 / 30%);
  }

  .submitBtn {
    width: 30%;
    margin-left: 20px;
  }

  .ImpoImg {
    height: 50px;
    width: 60px !important;
    color: #6c757d;
  }

  .noData {
    font-weight: bolder !important;
  }


  .img-card {
    position: relative;
    overflow: hidden;
  }

  .img-card .card-body {
    z-index: 9;
  }

  .img-card:after {
    content: "";
    height: 100%;
    width: 100%;
    position: absolute;
    bottom: 0;
    background-position: 0px 50px;
    background-repeat: no-repeat;
    background-size: cover;
    /* background-image: url("./../images/city.png") ; */

  }

  .content {
    width: 100% !important;
    height: 100%;
  }


  .card-header .card-title {
    justify-content: space-between;
  }

  button {
    font-size: initial;
  }


  .fixedleftcolumn {
    position: sticky !important;
    right: 0;
  }

  .bg-secondary {
    --bs-bg-opacity: 1;
    background-color: rgba(228, 230, 239, var(--bs-bg-opacity)) !important;
  }


  .flex-stack {
    justify-content: space-between;
    align-items: center;
  }

  .card .card-footer {
    padding: 1rem;
    background-color: transparent;
    border-top: 1px solid #eff2f5;
  }



  .page-item {
    margin: 0 0.1rem 0 0.1rem;
  }

  .pagination {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0;
  }

  .page-item .page-link {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.475rem;
    height: 1.5rem;
    min-width: 1.5rem;
    font-weight: 500;
    font-size: 0.8rem !important;
  }

  .card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid #eff2f5;
    border-radius: 0.475rem;
    box-shadow: 0 0 20px 0 rgb(76 87 125 / 2%);
  }

  .fs-page {
    font-size: 0.8rem;
  }

  .min-w-90px {
    min-width: 90px !important;
  }

  .min-w-95px {
    min-width: 95px !important;
  }

  .min-w-100px {
    min-width: 100px !important;
  }

  .min-w-125px {
    min-width: 125px !important;
  }

  .min-w-150px {
    min-width: 150px !important;
  }

  .min-w-175px {
    min-width: 175px !important;
  }

  .min-w-200px {
    min-width: 200px !important;
  }

  .table {
    --bs-table-bg: transparent;
    --bs-table-accent-bg: transparent;
    --bs-table-striped-color: #181c32;
    --bs-table-striped-bg: rgba(245, 248, 250, 0.75);
    --bs-table-active-color: #181c32;
    --bs-table-active-bg: #f5f8fa;
    --bs-table-hover-color: #181c32;
    --bs-table-hover-bg: #f5f8fa;
    width: 100%;
    margin-bottom: 1rem;
    color: #181c32;
    vertical-align: top;
    border-color: #eff2f5;
  }

  table {
    caption-side: bottom;
    border-collapse: collapse;
  }

  .table {
    width: 100%;
  }

  .table> :not(caption)>*>* {
    padding: 0.75rem 0.75rem;
    background-color: var(--bs-table-bg);
    border-bottom-width: 1px;
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
  }

  .table>tbody {
    vertical-align: inherit;
  }

  .table>thead {
    vertical-align: bottom;
  }

  .table> :not(:first-child) {
    border-top: 2px solid currentColor;
  }

  .table> :not(:last-child)> :last-child>* {
    border-bottom-color: inherit;
  }

  .table td,
  .table th,
  .table tr {
    border-color: inherit;
    border-width: inherit;
    border-style: inherit;
    text-transform: inherit;
    font-weight: inherit;
    font-size: inherit;
    color: inherit;
    height: inherit;
    min-height: inherit;
  }

  .table td:first-child,
  .table th:first-child,
  .table tr:first-child {
    padding-left: 0;
  }

  .table td:last-child,
  .table th:last-child,
  .table tr:last-child {
    padding-right: 0;
  }

  .table tbody tr:last-child,
  .table tfoot tr:last-child {
    border-bottom: 0 !important;
  }

  .table tbody tr:last-child td,
  .table tbody tr:last-child th,
  .table tfoot tr:last-child td,
  .table tfoot tr:last-child th {
    border-bottom: 0 !important;
  }

  .table tfoot td,
  .table tfoot th {
    border-top: inherit;
  }

  .table-responsive {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  @media (max-width: 575.98px) {
    .table-responsive-sm {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }
  }

  @media (max-width: 767.98px) {
    .table-responsive-md {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }
  }

  @media (max-width: 991.98px) {
    .table-responsive-lg {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }
  }

  @media (max-width: 1199.98px) {
    .table-responsive-xl {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }
  }

  @media (max-width: 1399.98px) {
    .table-responsive-xxl {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }
  }

  @media (max-width:470px) {

    .card-footer div {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .card-footer .fs-page {
      margin-top: 5px;
      order: 2;
    }
  }

  .form1 {
    border-radius: 1rem;
  }

  @media (max-width: 768px) {
    .w-md-man {
      width: 100% !important;
    }
  }

  @media (min-width: 768px) {
    .w-md-man {
      min-width: 20% !important;
    }
  }

  .w-60 {
    width: 60% !important;
  }

  .flex-center {
    align-items: center;
    justify-content: center;
  }

  @media (min-width: 1200px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
      max-width: 1287px !important;
    }
  }

  @media (min-width: 1200px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
      width: unset !important;
    }
  }

  @media (min-width: 1200px) {

    .container2,
    .container-lg1,
    .container-md1,
    .container-sm1,
    .container-xl1 {
      max-width: 1287px !important;
      margin-left: auto;
      margin-right: auto;
    }
  }

  @media (min-width: 769px) {

    .container2,
    .container-lg1,
    .container-md1,
    .container-sm1,
    .container-xl1 {
      max-width: 550px !important;
      margin-left: auto;
      margin-right: auto;
    }
  }

  @media (min-width: 1366px) {
    .container-xxl1 {
      max-width: 1320 !important;
    }
  }

  .table-bordered thead th,
  .table-bordered tbody td,
  .table-bordered tbody tr:last-child,
  .table-bordered tfoot tr:last-child {
    border: 1px solid rgb(239, 242, 245) !important;
  }

/* notification */
.menu-sub {
  background-color: white;
  border-radius: 7px;
  box-shadow: 0 0 50px 0 rgb(82 63 105 / 10%);
  position: absolute;
  inset: 0px 0px auto auto;
  margin: 0px;
  top: 60px;
  right: 0;
}
.menu-sub {
  padding: 0;
  margin: 0;
  list-style: none;
  flex-direction: column;
  /* right: -279px !important; */
  left: auto !important;
}
@media (min-width: 992px){
.w-lg-375px {
  width: 375px !important;
}
}
.px-9 {
  padding-right: 2.25rem !important;
  padding-left: 2.25rem !important;
}
.px-8 {
  padding-right: 1.25rem !important;
  padding-left: 1.25rem !important;
}
.mb-6 {
  margin-bottom: 1.5rem !important;
}
.mt-10 {
  margin-top: 2.5rem !important;
}
.nav-stretch {
  align-items: stretch;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.nav-line-tabs {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #eff2f5;
  justify-content: start;
}
.nav-line-tabs.nav-line-tabs-2x {
  border-bottom-width: 2px;
}
.pro-sidebar .pro-menu a {
  color: #7c7c7c;
}
.pro-sidebar .pro-menu a:hover {
  color: #7c7c7c;
}