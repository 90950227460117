#NotificatioContent{
    display: none;
}
.noti{
    border-radius: 15px;
    padding:10px;
    width: 100%;
}
.noti:hover{
    background-color: rgba(77, 151, 194, 0.2);
    
}
.unred{
    background-color: rgba(77, 151, 194, 0.1);

}
.noti:hover .text-hover-primary {
    color: #009ef7 !important;
    
}
.title-header{
    font-size: 2em;
    color:#181c32 !important;
}
.cardtitle{
    position: relative;
    height: 100px;
  
}
.card-info{
    border-radius: 15px;
    background: white;
    padding: 10px;
    height: 60px;
    display: flex;
    align-items: center;
}

.complaintdanger{
    border-radius: 15px;
     background-color: rgb(241,65,108); 
    height: 80px;
    position: relative;
    background-image: url('./img/danger.svg');
    background-repeat: no-repeat;
    background-size: 40px;
}
.down{
 position:absolute; 
 bottom: 0; 
}
.c-info{
    border-radius: 15px;
    background: white;
    padding: 10px;

}
.headdata{
    background:#009ef7;
    color:white;
    border-radius: 15px;
    height: 80px;
    display: flex;
    padding-top: 10px;
    align-items: start;
}
.c-info{
    margin-top: -35px;
padding:10px ;
    width: 100% !important;
    
}
.headcomment{
    background:#009ef7;
    color:white;
    border-radius: 15px;
    height: 80px;
    display: flex;
    padding-top: 10px;
    align-items: start;

}
.commentcon{
background:#f1faff ;
padding: 10px;
}
.text-color{
    color:#3f4254!important
}

.addcomment{
    width: 100% !important;
    margin: 20px 0 5px 0 ;
    position: relative;
}
.commentinput{

    border-radius: 25px;
}
.commentinput:focus{

   border-color: #009ef7 !important;
   border-width: 2px;
}
.sendcomment{
    position: absolute;
    top: 10px;
    left: 25px;
    transition: all 0.2s;
}
.sendcomment:hover{
    transform: scale(1.05);
 
}
.sendcomment:active{
    transform: scale(1.2);
 
}
.allread{
    color: white;
    opacity: 0.8;
    font-size: 11px;
    background: rgba(255, 255, 255, 0.3);
    line-height: 1.5;
    padding: 2px 5px;
    border-radius: 9px;

}

.allread:hover{
    opacity: 1;
    color: white;
    transform: scale(1.01);
}
.nav-line-tabs.nav-line-tabs-2x .nav-item {
    margin-bottom: -2px;
}
.nav-stretch .nav-item {
    display: flex;
    align-items: stretch;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    width: unset;
    height: unset;
    text-align: left;
    background: unset;
    border-radius: unset;
    margin-bottom: unset;
    border: 0px solid #dcdcdc85;
}
.nav-line-tabs.nav-line-tabs-2x .nav-item .nav-link.active, .nav-line-tabs.nav-line-tabs-2x .nav-item .nav-link:hover:not(.disabled), .nav-line-tabs.nav-line-tabs-2x .nav-item.show .nav-link {
    border-bottom-width: 2px;
}
.nav-line-tabs.nav-line-tabs-2x .nav-item .nav-link {
    border-bottom-width: 2px;
}
.nav-line-tabs .nav-item .nav-link.active, .nav-line-tabs .nav-item .nav-link:hover:not(.disabled), .nav-line-tabs .nav-item.show .nav-link {
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid #009ef7;
    transition: color 0.2s ease, background-color 0.2s ease;
}
.nav-line-tabs .nav-item:last-child .nav-link {
    margin-right: 0;
}
.nav-line-tabs .nav-item:first-child .nav-link {
    margin-left: 0;
}
.nav-line-tabs .nav-item .nav-link {
    color: #a1a5b7;
    border: 0;
    border-bottom: 1px solid transparent;
    transition: color 0.2s ease, background-color 0.2s ease;
    padding: 0.5rem 0;
    margin: 0 1rem;
}
.nav-stretch .nav-link {
    display: flex;
    align-items: center;
}
.opacity-state-100.active, .opacity-state-100:hover {
    opacity: 1 !important;
}
.text-white {
    color: #fff !important;
}
.text-white {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}
.pb-4 {
    padding-bottom: 1rem !important;
}
.nav-link {
    font-size: unset !important;
}
@media (max-width: 991px){
.menu-sub {
    top: 50px;
}
}
.w-250px {
    width: 250px !important;
}
@media (min-width: 768px){
.w-md-350px {
    width: 350px !important;
}
}
@media (min-width: 1200px){
.h3, h3 {
    font-size: 1.25rem;
}
}
.h3, h3 {
    font-size: calc(1.26rem + 0.12vw);
}
@media (min-width: 500px){
    .h3, h3 {
        font-size: 1rem;
    }
    }
.scroll-y {
    overflow-y: scroll;
    position: relative;
}
.my-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
}

.mh-325px {
    max-height: 325px !important;
}
.text-gray-400 {
    color: #b5b5c3 !important;
}

.fs-4 {
    font-size: 1.10rem !important;
}
.btn.btn-color-gray-600 {
    color: #7e8299;
  }
  .btn.btn-color-gray-600 i {
    color: #7e8299;
  }
  .btn.btn-color-gray-600 .svg-icon svg [fill]:not(.permanent):not(g) {
    transition: fill 0.3s ease;
    fill: #7e8299;
  }
  .btn.btn-color-gray-600 .svg-icon svg:hover [fill]:not(.permanent):not(g) {
    transition: fill 0.3s ease;
  }
  .btn.btn-color-gray-600.dropdown-toggle:after {
    color: #7e8299;
  }
  .menu-hover-title-primary
  .menu-item
  .menu-link:hover:not(.disabled):not(.active),
.menu-hover-title-primary
  .menu-item.hover
  > .menu-link:not(.disabled):not(.active) {
  transition: color 0.2s ease, background-color 0.2s ease;
  color: #fff;
}
.menu-hover-title-primary
  .menu-item
  .menu-link:hover:not(.disabled):not(.active)
  .menu-title,
.menu-hover-title-primary
  .menu-item.hover
  > .menu-link:not(.disabled):not(.active)
  .menu-title {
  color: #fff;
}
.btn-check:active + .btn.btn-active-light-primary, .btn-check:checked + .btn.btn-active-light-primary, .btn.btn-active-light-primary.active, .btn.btn-active-light-primary.show, .btn.btn-active-light-primary:active:not(.btn-active), .btn.btn-active-light-primary:focus:not(.btn-active), .btn.btn-active-light-primary:hover:not(.btn-active), .show > .btn.btn-active-light-primary {
    color: rgb(0, 155, 175) !important;
    border-color: #ffff;
    background-color: #ffff !important;
    border-radius: 50%;
}
.btn-icon {
    opacity: 1;
}
.btn {
    outline: 0 !important;
}
.svg-icon {
    color: unset;
}
.btn-check:active + .btn.btn-active-light-primary .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:checked + .btn.btn-active-light-primary .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-primary.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-primary.show .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-primary:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-primary:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-light-primary:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .show > .btn.btn-active-light-primary .svg-icon svg [fill]:not(.permanent):not(g) {
    transition: fill 0.3s ease;
    fill: rgb(0, 155, 175);
}