body {
  display: flex;
  flex-direction: column;
  color: #181C32;
}
.bg-image {
  background-image: url('../src/images/header-bg.jpg');
  /* background-size: cover; */
  /* background-position: center center; */
  background-color: #f5f8fa;
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 100% 350px;
}
.bg-image-notification {
  background-image: url('../src/images/header-bg.jpg');
  /* background-size: cover; */
  /* background-position: center center; */
  background-color: #f5f8fa;
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 100% 105px;
}

.bg-image-2{
  background-image: url('../src/images/pattern-1.jpg');
}
.bg-image-4{
  background-image: url('../src/images/taieri.svg');
  background-position: right;
  background-color: #663259 !important;
}
/* .bg-image-login{
  background-image: url('../src/images/14.png');
} */
@media (min-width: 992px){
main::-webkit-scrollbar, span::-webkit-scrollbar, ol::-webkit-scrollbar, ul::-webkit-scrollbar, pre::-webkit-scrollbar, div::-webkit-scrollbar {
    width: 0.4rem;
    height: 0.4rem;
}
}
@media (min-width: 992px){
main::-webkit-scrollbar-thumb, span::-webkit-scrollbar-thumb, ol::-webkit-scrollbar-thumb, ul::-webkit-scrollbar-thumb, pre::-webkit-scrollbar-thumb, div::-webkit-scrollbar-thumb {
    background-color: #e3e3e3;
}
}
@media (min-width: 992px){
.wrapper {
    transition: padding-left ease, margin-right ease;
}
}
.flex-row-fluid {
  flex: 1 auto;
  min-width: 0;
}
@media (min-width: 992px){
.header {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    position: relative;
    z-index: 2;
    height: 80px;
    transition: left ease;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
}
.custome-top {
   top:4.08rem;
}
@media (min-width: 992px){
  .custome-top {
     top:5rem;
  }
  }

.container, .container-fluid, .container-xxl, .container-xl, .container-lg, .container-md, .container-sm {
  /* width: 100%; */
  padding-right: var(--bs-gutter-x, 1.5rem);
  padding-left: var(--bs-gutter-x, 1.5rem);
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 992px){
.container, .container-xxl, .container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
    padding: 0 30px;
}
}

@media (min-width: 992px){
.container, .container-fluid, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    padding: 0 30px;
}
}
 /* @media (min-width: 1300){
  .container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
     width: 1320px !important;
  }
  } */
/*  .container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    width: 1132px !important;
  
} */
.ms-n2 {
  margin-left: -0.5rem !important;
}

.me-3 {
  margin-right: 0.75rem !important;
}
.btn.btn-icon:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush) {
  border: 0;
}
.btn:not(.btn-shadow):not(.shadow):not(.shadow-sm):not(.shadow-lg) {
  box-shadow: none !important;
}
.btn.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  height: calc(1.5em + 1.5rem + 2px);
  width: calc(1.5em + 1.5rem + 2px);
}
/* .btn {
  outline: none !important;
} */
.h-20px {
  height: 20px !important;
}
.h-30px {
  height: 30px !important;
}
.h-60px {
  height: 60px !important;
}
.w-30px {
  width: 30px !important;
}
@media (min-width: 768px){
.h-md-40px {
  height: 40px !important;
}
}
@media (min-width: 768px){
.w-md-40px {
  width: 40px !important;
}
}
/* .btn {
  display: inline-block;
  font-weight: 500;
  line-height: 1.5;
  color: #181C32;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.75rem 1.5rem;
  font-size: 1.1rem;
  border-radius: 0.475rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
} */
body:not([data-kt-sticky-header=on]) .topbar .btn.btn-custom i, body:not([data-kt-sticky-header=on]) .topbar .btn.btn-custom .svg-icon {
  color: #fff;
}
.btn.btn-icon i, .btn.btn-icon .svg-icon {
  padding: 0;
  margin: 0;
  line-height: 1;
}
.btn .svg-icon {
  flex-shrink: 0;
  line-height: 0;
  margin-right: 0.5rem;
}
.svg-icon {
  line-height: 1;
  color: #A1A5B7;
}
.svg-icon.svg-icon-1 svg {
  height: 1.75rem !important;
  width: 1.75rem !important;
}

.svg-icon svg {
  height: 1.15rem;
  width: 1.15rem;
}
img, svg {
  vertical-align: middle;
}
@media (min-width: 992px){
.header .header-logo {
    padding: 0;
    display: flex;
    align-items: center;
}
}
@media (min-width: 992px){
.flex-lg-grow-0 {
    flex-grow: 0 !important;
}
}
@media (min-width: 768px){
.me-md-10 {
    margin-right: 29.5rem !important;
}
}
@media (min-width: 769px){
  .me-md-10 {
      margin-right: 24.5rem !important;
}
}
@media (min-width: 992px){
  .me-md-10 {
      margin-right: 33.5rem !important;
}
}
@media (min-width: 1024px){
  .me-md-10 {
      margin-right: 37.5rem !important;
}
}
@media (min-width: 1070px){
  .me-md-10 {
      margin-right: 40.5rem !important;
}
}
@media (min-width: 1125px){
  .me-md-10 {
      margin-right: 44.5rem !important;
}
}
@media (min-width: 1195px){
  .me-md-10 {
      margin-right: 48.5rem !important;
}
}
@media (min-width: 1260px){
  .me-md-10 {
      margin-right: 52.5rem !important;
}
}
@media (min-width: 1310px){
  .me-md-10 {
      margin-right: 55.5rem !important;
}
}
@media (min-width: 992px){
.header .header-logo .logo-default {
    display: inline-block;
}
}
.h-25px {
    height: 25px !important;
}
.h-65px {
  height: 65px !important;
}
@media (min-width: 992px){
.header .header-logo .logo-sticky {
    display: none;
}
}
@media (min-width: 992px){
.header .header-menu {
    display: flex;
}
}
[data-kt-drawer=true] {
    display: none;
}
.menu-column {
    flex-direction: column;
    width: 100%;
}
@media (min-width: 992px){
.menu-lg-row {
    flex-direction: row;
}
}
.menu, .menu-wrapper {
    display: flex;
    padding: 0;
    margin: 0;
    list-style: none;
}
@media (min-width: 992px){
.my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}
}
.fw-bold {
    font-weight: 500 !important;
}
@media (min-width: 992px){
.menu-lg-row > .menu-item {
    display: flex;
    align-items: center;
}
}
.menu-item {
    display: block;
    padding: 0;
}
@media (min-width: 992px){
body:not([data-kt-sticky-header=on]) .header .header-menu .menu > .menu-item.here > .menu-link {
    transition: color 0.2s ease, background-color 0.2s ease;
    background-color: rgba(255, 255, 255, 0.1);
    color: #ffffff;
}
}
@media (min-width: 992px){
body:not([data-kt-sticky-header=on]) .header .header-menu .menu > .menu-item > .menu-link {
    color: #fff;
}
}
.menu-state-bg .menu-item.here > .menu-link {
    transition: color 0.2s ease, background-color 0.2s ease;
    background-color: rgba(245, 248, 250, 0.8);
}
.menu-title-gray-700 .menu-item .menu-link {
    color: #5E6278;
}
@media (min-width: 992px){
.menu-lg-rounded .menu-link {
    border-radius: 0.475rem;
}
}
.menu-item .menu-link {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0;
    flex: 0 0 100%;
    padding: 0.65rem 1rem;
    transition: none;
    outline: none !important;
}
.py-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
}
@media (min-width: 992px){
body:not([data-kt-sticky-header=on]) .header .header-menu .menu > .menu-item.here > .menu-link .menu-title {
    color: #ffffff;
}
}
@media (min-width: 992px){
body:not([data-kt-sticky-header=on]) .header .header-menu .menu > .menu-item > .menu-link .menu-title {
    color: #fff;
}
}
.menu-title-gray-700 .menu-item .menu-link .menu-title {
    color: #5E6278;
}
.menu-item .menu-link .menu-title {
    display: flex;
    align-items: center;
    flex-grow: 1;
}
.menu-item .menu-link .menu-arrow {
  display: flex;
  align-items: stretch;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  margin-left: 0.5rem;
  width: 0.8rem;
  height: 0.8rem;
}
.ms-1 {
    margin-left: 0.25rem !important;
}
@media (min-width: 992px){
.ms-lg-3 {
    margin-left: 0.75rem !important;
}
}
.menu-sub-dropdown {
  display: none;
  border-radius: 0.475rem;
  background-color: #ffffff;
  box-shadow: 0px 0px 50px 0px rgb(82 63 105 / 15%);
  z-index: 107;
}
.menu-sub {
  display: none;
  padding: 0;
  margin: 0;
  list-style: none;
  flex-direction: column;
}
@media (min-width: 992px){
.w-lg-225px {
    width: 225px !important;
}
}
@media (min-width: 992px){
.menu-sub-lg-dropdown {
    display: none;
    border-radius: 0.475rem;
    background-color: #ffffff;
    box-shadow: 0px 0px 50px 0px rgb(82 63 105 / 15%);
    z-index: 107;
}
}
.hover-scroll-overlay-y {
  overflow-y: hidden;
  position: relative;
  --scrollbar-space: 0.5rem;
}
.mh-300px {
  max-height: 300px !important;
}
.show.menu-dropdown > .menu-sub-dropdown, .menu-sub-dropdown.menu.show, .menu-sub-dropdown.show[data-popper-placement] {
  display: flex;
  animation: menu-sub-dropdown-animation-fade-in 0.3s ease 1, menu-sub-dropdown-animation-move-up 0.3s ease 1;
}
@media (min-width: 992px) {
  .show.menu-dropdown >
.menu-sub-lg-dropdown , .menu-sub-lg-dropdown.menu.show , .menu-sub-lg-dropdown.show[data-popper-placement]{
  display: flex;
  animation: menu-sub-dropdown-animation-fade-in 0.3s ease 1, menu-sub-dropdown-animation-move-up 0.3s ease 1;
}
}
.rounded {
  border-radius: 0.475rem !important;
}
.w-275px {
  width: 275px !important;
}
.btn-check:checked + .btn.btn-active-light-primary, .btn-check:active + .btn.btn-active-light-primary, .btn.btn-active-light-primary:focus:not(.btn-active), .btn.btn-active-light-primary:hover:not(.btn-active), .btn.btn-active-light-primary:active:not(.btn-active), .btn.btn-active-light-primary.active, .btn.btn-active-light-primary.show, .show > .btn.btn-active-light-primary {
  color: #009EF7;
  border-color: #F1FAFF;
  background-color: #F1FAFF !important;
}
.btn-check:checked + body:not([data-kt-sticky-header=on]) .topbar .btn.btn-custom, .btn-check:active + body:not([data-kt-sticky-header=on]) .topbar .btn.btn-custom, body:not([data-kt-sticky-header=on]) .topbar .btn.btn-custom:focus:not(.btn-active), body:not([data-kt-sticky-header=on]) .topbar .btn.btn-custom:hover:not(.btn-active), body:not([data-kt-sticky-header=on]) .topbar .btn.btn-custom:active:not(.btn-active), body:not([data-kt-sticky-header=on]) .topbar .btn.btn-custom.active, body:not([data-kt-sticky-header=on]) .topbar .btn.btn-custom.show, .show > body:not([data-kt-sticky-header=on]) .topbar .btn.btn-custom {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
.toolbar {
  display: flex;
  align-items: center;
  background-color: #efefef;
  justify-content: space-between;
}

.fs-3 {
  font-size: calc(1.26rem + 0.12vw) !important;
}
@media (min-width: 1200px){
.fs-3 {
    font-size: 1.35rem !important;
}
}
.breadcrumb {
  display: flex;
  align-items: center;
  background-color: transparent;
  padding: 0;
  margin: 0;
}
.fs-7 {
  font-size: 0.95rem !important;
}
.breadcrumb .breadcrumb-item {
  display: flex;
  align-items: center;
  padding-left: 0;
  padding-right: 0.5rem;
}
.text-hover-primary {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.h-2px {
  height: 2px !important;
}

.w-5px {
  width: 5px !important;
}
.bullet {
  display: inline-block;
  background-color: #B5B5C3;
  border-radius: 6px;
  width: 8px;
  height: 4px;
  flex-shrink: 0;
}
@media (min-width: 992px){
.content {
    padding: 0;
}
}
@media (min-width: 1200px){
.g-xl-8, .gy-xl-8 {
    --bs-gutter-y: 2rem;
}
}
@media (min-width: 1200px){
.g-xl-8, .gx-xl-8 {
    --bs-gutter-x: 2rem;
}
}
@media (min-width: 1200px){
.col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
}
}
@media (min-width: 1200px){
.card.card-xl-stretch {
    height: calc(100% - var(--bs-gutter-y));
}
}
.card .card-header {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
  min-height: 70px;
  padding: 0 2.25rem;
  background-color: transparent;
  border-bottom: 1px solid #eff2f5;
}
.card-header:first-child {
  border-radius: calc(0.475rem - 1px) calc(0.475rem - 1px) 0 0;
}
/* 
.card-header:first-child {
  border-radius: calc(0.625rem - 1px) calc(0.625rem - 1px) 0 0;
} */
.py-5 {
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
}

@media (min-width: 992px){
  .py-lg-15 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important;
  }
  }
.border-0 {
  border-width: 0 !important;
}
.border-0 {
  border: 0 !important;
}
.card-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  background-color: #ffffff;
  border-bottom: 1px solid #EFF2F5;
}
.card .card-header .card-title.flex-column {
  align-items: flex-start;
  justify-content: center;
}

.card .card-header .card-title, .card .card-header .card-title .card-label {
  font-weight: 500;
  font-size: 1.2rem;
  color: #181C32;
}
.card .card-header .card-title {
  display: flex;
  align-items: center;
  margin: 0.5rem;
  margin-left: 0;
}
.card .card-header .card-title .card-label {
  margin: 0 0.75rem 0 0;
  flex-wrap: wrap;
}
.card .card-body {
  padding: 2rem 2.25rem;
}
.flex-stack {
  justify-content: space-between;
  align-items: center;
}

.mb-5 {
  margin-bottom: 1.25rem !important;
}
.symbol {
  display: inline-block;
  flex-shrink: 0;
  position: relative;
  border-radius: 0.475rem;
}
.symbol.symbol-50px .symbol-label {
  width: 50px;
  height: 50px;
}

.symbol .symbol-label {
  width: 50px;
  height: 50px;
}
.symbol .symbol-label {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  color: #3F4254;
  background-color: #F5F8FA;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-radius: 0.475rem;
}
.text-gray-800 {
  color: #3F4254 !important;
}
.text-muted {
  color: #A1A5B7 !important;
}
.badge {
  display: inline-block;
  padding: 0.325rem 0.5rem;
  font-size: 0.85rem !important;
  font-weight: 600;
  line-height: 1;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.325rem !important;
}
.badge-light {
    color: #7E8299;
    background-color: #F5F8FA;
}
.badge {
    display: inline-flex;
    align-items: center;
}
.fw-bold {
    font-weight: 500 !important;
}
.py-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
}
.px-3 {
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
}
@media (min-width: 1200px){
.col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
}
}
@media (min-width: 1200px){
.mb-xl-8 {
    margin-bottom: 2rem !important;
}
}
.bgi-size-cover {
  background-size: cover;
}
.bgi-no-repeat {
  background-repeat: no-repeat;
}
.h-150px {
  height: 150px !important;
}
.p-6 {
  padding: 1.5rem !important;
}
@media (min-width: 1200px){
.fs-2 {
    font-size: 1.5rem !important;
}
}
a{
  text-decoration: none;
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 1px solid #EFF2F5;
  border-radius: 0.625rem;
  box-shadow: 0px 0px 20px 0px rgb(76 87 125 / 2%);
}
.card {
  border: 0;
  box-shadow: 0px 0px 20px 0px rgb(76 87 125 / 2%);
}
.fw-bolder {
  font-weight: 600 !important;
}
.mb-3 {
  margin-bottom: 0.75rem !important;
}
@media (min-width: 1200px){
h2, .h2 {
    font-size: 1.5rem;
}
}
.btn:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush):not(.btn-icon) {
  border: 0;
  padding: calc(0.4rem + 1px) calc(1rem + 1px);
}
.btn.btn-primary {
  color: #FFFFFF;
  border-color: #009EF7;
  background-color: #009EF7;
}
.btn.btn-danger {
  color: #FFFFFF;
  border-color: #F1416C;
  background-color: #F1416C;
}
.px-6 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}
.svg-icon.svg-icon-white {
  color: #ffffff;
}
.fs-1 {
  font-size: calc(1.3rem + 0.6vw) !important;
}
@media (min-width: 1200px){
.fs-1 {
    font-size: 1.75rem !important;
}
}
.svg-icon.svg-icon-success {
  color: #50CD89;
}
.flex-root {
  flex: 1;
}
.flex-column-fluid {
  flex: 1 0 auto;
}
.bgi-attachment-fixed {
  background-attachment: fixed;
}

.bgi-size-contain {
  background-size: contain;
}
.bgi-position-y-bottom {
  background-position-y: bottom;
}
.flex-center {
  justify-content: center;
  align-items: center;
}

.p-10 {
    padding: 2.5rem !important;
}
@media (min-width: 992px){
.pb-lg-20 {
    padding-bottom: 5rem !important;
}
}
@media (min-width: 992px){
.p-lg-15 {
    padding: 3.75rem !important;
}
}
@media (min-width: 992px){
.w-lg-500px {
    width: 500px !important;
}
}
.shadow-sm {
  box-shadow: 0 0.1rem 1rem 0.25rem rgb(0 0 0 / 5%) !important;
}
.mb-10 {
  margin-bottom: 2.5rem !important;
}
@media (min-width: 1200px){
h1, .h1 {
    font-size: 1.75rem;
}
}
.text-dark {
  color: #181C32 !important;
}
h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 600;
  line-height: 1.2;
  color: #181C32;
}
.fv-plugins-icon-container {
  position: relative;
}
.form-control.form-control-solid {
  background-color: #F5F8FA;
  border-color: #F5F8FA;
  color: #5E6278;
  transition: color 0.2s ease, background-color 0.2s ease;
}

.form-control {
  box-shadow: none !important;
}
.form-control-lg {
  min-height: calc(1.2em + 1.65rem + 2px);
  padding: 0.825rem 1.5rem;
  font-size: 1.15rem;
  border-radius: 0.625rem;
}
.form-control {
  display: block;
  width: 100%;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  color: #5E6278;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #E4E6EF;
  appearance: none;
  border-radius: 0.475rem;
  box-shadow: inset 0 1px 2px rgb(0 0 0 / 8%);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
input, button, select, optgroup, textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
.link-primary {
  color: #009EF7;
}
a {
  transition: color 0.2s ease, background-color 0.2s ease;
}
.btn-lg, .btn-group-lg > .btn {
  padding: 0.825rem 1.75rem;
  font-size: 1.15rem;
  border-radius: 0.625rem;
}
.btn:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush):not(.btn-icon).btn-lg, .btn-group-lg > .btn:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush):not(.btn-icon) {
  padding: calc(0.825rem + 1px) calc(1.75rem + 1px);
}
.btn.btn-light {
  color: #7E8299;
  border-color: #F5F8FA;
  background-color: #F5F8FA;
}

.btn.btn-flex {
  display: inline-flex;
  align-items: center;
}
.btn-lg, .btn-group-lg > .btn {
  padding: 0.825rem 1.75rem;
  font-size: 1.15rem;
  border-radius: 0.625rem;
}
.sideBar{
  width: 120px;
  background-color: white;
  height: 650px;
}
.sideBar button{
  text-align:center;
  width: 58px;
  height: 58px;
  border-radius: 22px;
  border: none;
}
.section{
  margin-bottom: 3rem;
transition: all 1s;
}
/* @media  (min-width: 769px) {
.section{
  padding-left: 80px;
}
} */
@media (min-width: 992px){
.content {
    padding: 10px 0;
}
}
.text-dark {
  --bs-text-opacity: 1;
}
.breadcrumb {
  display: flex;
  align-items: center;
  background-color: transparent;
  padding: 0;
  margin: 0;
}
.btn-info {
  color: #000;
  background-color: #0dcaf0 !important;
  border-color: #0dcaf0 !important;
}
.ps-9 {
  padding-left: 2.25rem !important;
}
.form-control-sm {
  min-height: calc(1.1em + 1.1rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.925rem;
  border-radius: 0.475rem;
}
.form-control, .MuiInputBase-input {
  border-radius: 25px !important;
}
.ms-3 {
  margin-left: 0.75rem !important;
}
.svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #fff;
}
.css-1s2u09g-control, .css-b62m3t-container, .css-1pahdxg-container {
  border-radius: 25px !important;
}
.route-container {
  width: calc(100% - 70px);
}
.css-14el2xx-placeholder {
  color: #c6c7c7 !important;
  font-weight: 500;
}
.PhoneInputInput {
  border: 0 !important;
}
.bg-new{
  background-color: #f5f8fa;
}
:focus-visible{
  outline: unset !important;
}
.mh-47{
  min-height: 47px !important;
}
.mt-6{
  margin-top: 5rem;
}
.w-minus-80{
   width: calc(100% - 80px );
   position: fixed;
}
.zIndex-1021{
  z-index: 1021 !important;
}
.noti-num{
  position: absolute;
  background-color: #F1416C;
 
  display: block;
  align-items: flex-start;
  justify-content: center;
  top: -5px;
  right: -5px;
  color: white;
  font-size: 0.8rem;
  min-width: 1rem;
  height: 1rem;
  border-radius: 0.8rem;
  padding: 0 0.4rem;
  line-height: 1.1rem;
}
.pass-wrapper {
  position: relative;
  display: flex;
  margin-bottom: 14px;
}

.pass-wrapper i:hover {
  color: #009ef7;
  cursor: pointer;
}