.grid{
   /* height: 550px;
     width: 75% !important; */
    position: relative;
    /* right: 21rem;
    top: 7rem; */
}
.content{
    width: 100% !important;
    height: 100%;
}
.card1{
    width: 100%;
    height: 500px;
}
.container1 {
    margin-left: 0px;
    width: 100%;
    height: 550px;
  }
  .scroll {
    overflow-y: scroll;
    position: relative;
  }
  .scroll::-webkit-scrollbar-track {
    border-radius: 15px;
    background-color: #f5f5f5;
  }
  
  .scroll::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    background-color: #f5f5f5;
  }
  
  .scroll::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #c3c3c3;
  }
  .NewB2{
    background-color: #0c1e35;
    color: white;
    font-family: Poppins, Helvetica, "sans-serif";
    -webkit-font-smoothing: antialiased;
    border: none;
    border-radius: 0.475rem;
    height: 44px;
    width: max-content;
    padding: 0px 15px;
  }
  .tableHeaderRow {
    height: 50px;
    background-color: white;
    vertical-align: middle;
  }
  
  .tableHeaderRow .action {
    background-color: #d3d2d2;
    left: 0px;
  }
  .tableHeaderRow .control {
    background-color: #d3d2d2;
    left: 100px;
  }
  .tableBody td {
    min-width: 100px;
  }
  .tableBody .td-150{
    min-width: 150px;
  }
  .tableBody .td-250{
    min-width: 250px;
  }
  .tableBody .d-flex{
    color: #6c757d !important;
  }
/* .tableBody h4 {
    font-size: 1.25rem;
} */
.table-responsive .table{
  border-collapse: separate !important;
  border-spacing: 0;
}
  .fixedTd {
    z-index: 2;
    left: 0px;
    background-color: #d3d2d2 !important;
  }
  .fixedTdControl {
    z-index: 2;
    left: 100px;
    background-color: #d3d2d2 !important;
  }
  .actions {
    display: flex;
    background-color: #d3d2d2;
    /* margin-left: 30px; */
  }
  .actions .action1 {
    width: 40px;
    height: 40px;
    margin-left: 3px;
    border-radius: 20%;
    font-size: large;
    text-align: center;
    z-index: 2;
    background-color: #009ef7;
  }
  .actions .action3 {
    padding: 6px 2.6px 0px 0px;
    background-color: #0d6efd !important;
  }
  .actions .action2 {
    width: 40px;
    height: 40px;
    /* margin-left: 8px; */
    border-radius: 20%;
    font-size: large;
    text-align: center;
    z-index: 2;
    background-color: red;
  }
  .actions .action1 i {
    color: rgb(255, 255, 255);
  }
  .actions .action2 i {
    color: rgb(255, 255, 255);
  }
  .actions .action1 .edit {
    margin-top: 10px;
    margin-left: 5px;
    color: white;
    text-decoration: none;
    font-weight: bold;
  }
  .actions .action2 .delete {
    margin-top: 10px;
  }
  .details{
    margin: auto;
  }
  .details i{
    color: white;
    vertical-align: middle;
    margin-left: 0px !important;
  }
  .container1 th {
    color: rgb(66, 66, 66);
    font-size: 20px;
    font-weight: 600;
  }
  .table>:not(:first-child) {
    border-top: 2px solid #d3d2d2;
}
.table>:not(caption)>*>* {
    border-left-width: 1px;
}
.pagination1 {
    margin-bottom: 0;
    display: flex;
    list-style: none;
  }
  .text {
    font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
    -webkit-font-smoothing: antialiased;
    color: #5e6278;
    margin-top: 8px;
  }
  .customer-data span {
    font-weight: bold;
    color: #808080b8;
    font-size: 15px;
  }
  .grid2 {
    width:70%;
    /* position: absolute;
    right: 25rem;
    top: 7rem; */
  }
  .form1 {
    /* width: 866px;
    margin:0px;
    padding: 0px 50px; */
    font-family: sans-serif;
    background: #fff;
  }
  .font{
    font-family:"Cairo", sans-serif;
    font-size: 20px !important;
    color: #0c1e35;
   }
   .ImpoImg{
    height: 50px;
    width: 50px !important;
  }
  .card2{
    height: 418px;
  }
  .gridWidth{
    width: 90%;
  }
  .card-header h3{
    margin-bottom: 0px !important;
  }
  .LoadingCategoryItem {
    min-height: 150px;
    width: 100%;
    background-color: white !important;
    text-align: center;
    font-size: 70px;
    color: #4995d1 !important;
    position: absolute;
}

.saving span {
  animation-name: blink;
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
}

.saving span:nth-child(2) {
  animation-delay: .2s;
}

.saving span:nth-child(3) {
  animation-delay: .4s;
}

.saving-dots {
  font-size: 7rem !important;
}

@keyframes blink {
  0% {
      opacity: .2;
  }
  20% {
      opacity: .8;
  }
  100% {
      opacity: .2;
  }
}