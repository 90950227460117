.css-19kzrtu {
    padding: 0px !important;
}
.custome-link h4{
    cursor: pointer;
    color: gray !important;
}
.custome-link h4:hover{
    color: #009ef7 !important;
}
