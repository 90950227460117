#languageMenu{
    display: none;
}
.w-150px {
    width: 150px!important;
}
.menu-state-primary .menu-item .menu-link.active {
    transition: color .2s ease,background-color .2s ease;
    color: #009ef7;
}
.fs-6 {
    font-size: 1.075rem!important;
}
.symbol.symbol-20px.symbol-fixed .symbol-label, .symbol.symbol-20px .symbol-label, .symbol.symbol-20px>img {
    width: 20px;
    height: 20px;
}
.symbol.symbol-fixed .symbol-label, .symbol .symbol-label, .symbol>img {
    width: 50px;
    height: 50px;
}
.symbol>img {
    width: 100%;
    flex-shrink: 0;
    display: inline-block;
    border-radius: 0.475rem;
}
.menu-active-bg .menu-item .menu-link.active, .menu-hover-bg .menu-item.hover>.menu-link:not(.disabled):not(.active), .menu-hover-bg .menu-item .menu-link:hover:not(.disabled):not(.active), .menu-show-bg .menu-item.here>.menu-link, .menu-show-bg .menu-item.show>.menu-link, .menu-state-bg .menu-item.here>.menu-link, .menu-state-bg .menu-item.hover>.menu-link:not(.disabled):not(.active), .menu-state-bg .menu-item .menu-link.active, .menu-state-bg .menu-item .menu-link:hover:not(.disabled):not(.active), .menu-state-bg .menu-item.show>.menu-link {
    transition: color .2s ease,background-color .2s ease;
    background-color: rgba(245,248,250,.8);
}
.menu-item .menu-link {
    cursor: pointer;
    display: flex;
    align-items: center;
    flex: 0 0 100% !important;
    padding: 0.5rem 0.5rem !important;
    transition: none !important;
    outline: 0!important;
    font-size: smaller;
}
.menu-gray-800 .menu-item .menu-link, .menu-gray-800 .menu-item .menu-link .menu-icon i, .menu-gray-800 .menu-item .menu-link .menu-title {
    color: #3f4254;
}
.menu-rounded .menu-link {
    border-radius: 0.475rem !important;
}
.px-5 {
    padding-right: 1.25rem!important;
    padding-left: 1.25rem!important;
}
.fs-6 {
    font-size: 1.075rem!important;
}
.menu-sub {
    top: 43px !important;
}