/* .selected{
  width: 100%;
  font-size: 17px;
 transition: all 0.5s;
 color: white !important;

}*/
.selectedmenu{
  border-radius: 25px !important;
  color: #337AB7 !important;
  font-size: 17px !important;
  transition: all 0.5s;

} 
.sliderlogo{
  height: 50px;
  width: auto;
}
.pro-sidebar > .pro-sidebar-inner {
  background: #FFFFFF !important;
}
.pro-sidebar{
  color: #adadad;
  width: 261px !important;
  min-width: 261px !important;
}
.pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item:hover {
  color: #337AB7;
}
.pro-sidebar .pro-menu .pro-menu-item.active {
  color: #337AB7;
}
.pro-sidebar .pro-menu.shaped .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
  background-color: unset;
}
.pro-icon svg {
  height: 2rem!important;
  width: 2rem!important;
}
.pro-icon i {
  font-size: 1.7rem !important;
}






#profileIcon svg path,#Menu svg path{
  transition: fill .3s ease;
  fill: #a1a5b7;
 }
 #Menu{
   position: relative;
 z-index: 1022;
 }
 #Menu .fa-times{
  font-size: 20px;
  color: tomato;
}
#Menu .fa-times:hover{
transform: scale(1.1);
  color: tomato;
}
.cursor-pointer	{
  cursor: pointer;
}
.symbol {
  display: inline-block;
  flex-shrink: 0;
  position: relative;
  border-radius: 0.475rem;
}
.pro-sidebar > .pro-sidebar-inner{
  background: #FFFFFF !important;
 }
 .pro-sidebar{
  z-index: 1022;
 } 
 
 @media (max-width: 769px) {
  .pro-sidebar:not(.toggled) .pro-inner-list-item{
      transition: all 0.5s;
      display: none;
 
  }
 }
 .pro-sidebar-inner{
  position: relative !important;
  z-index:101 !important;
 }
 .pro-sidebar-inner{
  display: flex;
  align-items: center !important;
 }
 .pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout {
  z-index: 1022;
}
.active  .overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 150;

}