.footer{
    background: linear-gradient(45deg,rgb(23 46 54), rgb(65 143 169));
    color: white;
  }
  .footer h4{
    color: rgb(218, 218, 218);
  }

  .how .item{
    border: 5px solid white;
    padding: 20px;
    text-align: center;
    color: white;
    border-radius: 2rem;
  }
  .item i{
    color: white;
  }
  .item h5{
    color: white;
  }
  .how i{
    color: white;
  }
  /* .anim{
    position: relative;
    animation: arrows 3s ease-in-out infinite;
  }
  @keyframes arrows{
    0%{left: 0px;}
    25%{left:80px;}
    50%{left:90px;}
    75%{left:100px;}
    100%{left:110px;}
  } */